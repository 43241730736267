// variables.js
const BASE_URL_API = 'https://hpp.habacus.com';
// const BASE_URL_API = 'http://localhost:8002';
// const BASE_URL_API = 'https://dev-hpp.habacus.com';

export const URL_LOGIN = BASE_URL_API + "/course/login";
export const URL_GET_NAME = BASE_URL_API + "/course/getName";
export const URL_IS_AUTHORIZED = BASE_URL_API + "/course/isAuthorized";
export const URL_START_COURSE = BASE_URL_API + "/course/startCourse";
export const URL_END_COURSE = BASE_URL_API + "/course/endCourse";
export const URL_GET_TOKEN = BASE_URL_API + "/authentication/get_token";

export const DELAY_MINUTES = 5;
export const HOW_LONG_WILL_YOU_SPIN = 10000;

export const CONFIG = {
    headers: {
        credentials: 'include',
    }
}